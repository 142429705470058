import React, { Suspense } from "react";
import { Spin } from "antd";

export type PropsLayout = {
  children: React.ReactNode;
};
const LayoutPage = ({ children }: any) => {
  return (
    <div className="w-full">
      <Suspense
        fallback={
          <Spin
            size="large"
            className="w-full h-[80vh] flex items-center justify-center  "
          />
        }
      >
        {children}
      </Suspense>
    </div>
  );
};
export default LayoutPage;
