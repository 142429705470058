import React from "react";

const Home = React.lazy(() => import("../components/organisms/pages/Home"));
const Post = React.lazy(() => import("../components/organisms/pages/Post"));
const Product = React.lazy(() => import("../components/organisms/pages/Product"));
const Cart = React.lazy(() => import("../components/organisms/pages/Cart"));

export const routePortals = {
  HOME: "/",
  PRODUCT: "/product",
  CART: "/cart",
  POST: "/:slug",
};
export const routePortal = [
  {
    id: 1,
    route: routePortals.HOME,
    component: Home,
    permission: "dashboard",
  },
  {
    id: 2,
    route: routePortals.POST,
    component: Post,
  },
  {
    id: 3,
    route: routePortals.PRODUCT,
    component: Product,
  },
  {
    id: 4,
    route: routePortals.CART,
    component: Cart,
  },
];
